





























































































































import Vue from 'vue';
import TextField from '@/components/Mobile/TextField.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: { TextField },
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      errorMessage: 'getErrorMessage',
    }),
  },
  methods: {
    submitForm() {
      const form = this.form;
      this.$store.dispatch('auth/registerUser', form);
    },
  },
});
